import React, { useContext, useState } from 'react'
import { Context } from "../../context";
import Slot from '../Slot'

export default function Section03() {

    const { setCurrentSection, prizeData } = useContext(Context);
    const [currentResult, setCurrentResult] = useState(0);

    const handleRolling = () => {
        setCurrentResult(prizeData ? 1 : 2);
        setTimeout(() => {
            console.log(prizeData);
            setCurrentSection(prizeData ? '4' : '5');
        }, 4000)
    };


    const slotContainerClasses = `
    slot-container 
    result-${currentResult}
  `;

    const buttonText = 'Старт!';

    return (
        <section className='section03'>
            <div className="section03__bg-lines"/>
            <div className={slotContainerClasses}>
                <Slot/>
            </div>
            {currentResult === 0 && (
                <button
                    onClick={handleRolling}
                    className="button  button--orange"
                >
                    {buttonText}
                </button>
            )}
        </section>
    )
}