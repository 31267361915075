
import React, {useContext} from 'react'
import {Context} from "../../context";
import tomatoEl from "../../images/tomato-essence.png";

export default function Section05() {
  return (
    <section className='section05'>

      <div className="section05__top-row">
        <div className="section05__title">
          Ви виграли
        </div>

        <div className="section05__sum  section05__sum--extra">
          $100
        </div>

        <div className="section05__label">
          на використання в&nbsp;Mr.Tomato
        </div>

        <img className="section05__image" src={tomatoEl} alt=""/>
      </div>

      <div className="section05__bottom-row">
        <div className="section05__appeal">
          Зверніться до нашого менеджера біля&nbsp;стійки&nbsp;Mr.Tomato
        </div>
      </div>

    </section>
  )
}