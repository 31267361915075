
import React from 'react'

export default function Slot() {
  return (
    <div className="slot">
      <div className="slot__frame">
        <div className="slot__bar"></div>
        <div className="slot__bar"></div>
        <div className="slot__bar"></div>
      </div>
    </div>
  )
}