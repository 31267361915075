import React, { useState, useReducer, useEffect } from 'react';
import './styles/index.scss'
import { Context } from './context'
import Section01 from './components/Section01'
import Section02 from './components/Section02'
import Section03 from './components/Section03'
import Section04 from './components/Section04'
import Section05 from './components/Section05'
import Logo from "./components/Logo";



export default function App() {
  const [currentSection, setCurrentSection] = useState('0');
  const [prizeData, setPrizeData] = useReducer((data, newData) => newData, null);

  useEffect(() => {
    console.log('effect', prizeData);
  }, [prizeData]);
  const containerClasses = `
    container 
    ${currentSection === '0' ? 'container--section01' : ''}
    ${currentSection === '1' ? 'container--section01' : ''}
    ${currentSection === '2' ? 'container--section02' : ''}
    ${currentSection === '3' ? 'container--section03' : ''}
    ${currentSection === '4' ? 'container--section04' : ''}
    ${currentSection === '5' ? 'container--section05' : ''}
  `;


  return (
    <Context.Provider value={{
      setCurrentSection,
      currentSection,
      prizeData,
      setPrizeData,
    }}>
      <div className="App">

        <div className={containerClasses}>
          <div className="falling-coin  falling-coin--big" />
          <div className="falling-coin  falling-coin--small" />

          <Logo />

          {
            (() => {
              switch (currentSection) {
                case "0":   return <Section01 />;
                case "1":   return <Section01 />;
                case "2":   return <Section02 />;
                case "3":   return <Section03 />;
                case "4":   return <Section04 />;
                case "5":   return <Section05 />;
                default:    return 'Error occured'
              }
            })()
          }


        </div>


      </div>
    </Context.Provider>
  );
}