
import React, { Component } from 'react'
import './Logo.scss';
import mrtomatoLogo from "../../images/mrtomato-logo.png";

class Logo extends Component {
  render() {

    return (
      <div className="logo">
        <img className="logo__image" src={mrtomatoLogo} alt="Mr. Tomato Logo"/>

        <div className="logo__label">
          Навчання для&nbsp;покоління&nbsp;Instagram
        </div>
      </div>
    )
  }
}

export default Logo;
