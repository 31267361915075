import React, {useState, useContext} from 'react'
import {Context} from '../../context'

export default function Section02() {
  const {setCurrentSection, setPrizeData } = useContext(Context);
  const [name, setName] = useState('');
  const [isPhoneBeenUsed, setIsPhoneBeenUsed] = useState(false);
  const [phoneErrorMsgText, setPhoneErrorMsgText] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');

  const handleChangeName = event => {
    setName(event.target.value);
  };
  const handleChangePhoneNumber = event => {
    setIsPhoneBeenUsed(false);
    setPhoneNumber(event.target.value);
  };
  const handleChangeCompany = event => {
    setCompany(event.target.value);
  };
  const handleChangePosition = event => {
    setPosition(event.target.value);
  };


  const handleSubmit = event => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_API}api/Lottery/AddLead`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        phoneNumber: phoneNumber,
        company: company,
        position: position,
      })
    })
      .then(function(response){
        if (response.status === 400) {
          setIsPhoneBeenUsed(true);
          setPhoneErrorMsgText('Будь ласка, введіть правильний номер телефону.');
        } else if (response.status === 403) {
          setIsPhoneBeenUsed(true);
          setPhoneErrorMsgText('Цей номер телефону уже використовується.');
        } else {
          setIsPhoneBeenUsed(false);
          response.json().then((data) => {
            console.log('data', data.prize);
            setPrizeData(data.prize);
            setCurrentSection('3');
          });
        }
      })
      .catch(err => {
        console.log('error');
      });
  };

  return (
    <section className='section02'>

      <div className="section02__title">
        <div className="section02__title-text">
          Бажаєте виграти<br/>
          приз прямо зараз?
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="section02__form"
      >
        <div className="section02__item">
          <div className="form-label">
            Введіть ваше ім'я
          </div>
          <input
            onChange={handleChangeName}
            name="name"
            type="text"
            className="section02__input"
            required
          />
        </div>
        <div className="section02__item">
          <div className="form-label">
            Введіть ваш телефон
          </div>
          <input
            onChange={handleChangePhoneNumber}
            name="phoneNumber"
            type="text"
            className={`section02__input ${isPhoneBeenUsed ? 'section02__input--error' : ''}`}
            required
          />
          {isPhoneBeenUsed && (
              <div className="form-error">
                {phoneErrorMsgText}
              </div>
          )}
        </div>
        <div className="section02__item">
          <div className="form-label">
            Ваша компанія
          </div>
          <input
            onChange={handleChangeCompany}
            name="company"
            type="text"
            className="section02__input"
          />
        </div>
        <div className="section02__item">
          <div className="form-label">
            Ваша посада
          </div>
          <input
            onChange={handleChangePosition}
            name="position"
            type="text"
            className="section02__input"
          />
        </div>
        <button
          type="submit"
          className="button  button--green"
        >
          Хочу виграти!
        </button>
      </form>

    </section>
  )
}

