
import React, { useContext } from 'react'
import tomatoEl from '../../images/tomato-essence.png'
import {Context} from '../../context'

export default function Section04() {
    const { prizeData } = useContext(Context);
  return (
    <section className='section05'>
      <div className="section05__title">
        Ви виграли
      </div>

      <div className="section05__sum">
          {prizeData.value}
      </div>

      <div className="section05__label  section05__label--top">
        від ресторану {prizeData.name}
      </div>

      <div className="section05__sum  section05__sum--extra">
        $100
      </div>

      <div className="section05__label">
        на використання в&nbsp;Mr.Tomato
      </div>

      <img className="section05__image" src={tomatoEl} alt=""/>

      <div className="section05__appeal">
        Зверніться до нашого менеджера біля&nbsp;стійки&nbsp;Mr.Tomato
      </div>


    </section>
  )
}