
import React, { useContext } from 'react'
import { Context } from '../../context'
import tomatoItem from "../../images/tomato-item.png";


export default function Section01() {
  const { setCurrentSection } = useContext(Context);
  return (
    <section className='section01'>

      <div className="section01__text">
        Беріть&nbsp;участь&nbsp;у&nbsp;нашому миттєвому&nbsp;розіграші призів
      </div>

      <img className="section01__tomato-item" src={tomatoItem} alt="Mr. Tomato character"/>

      <button
        onClick={() => setCurrentSection('2')}
        className="button  button--orange"
      >
        Вперед!
      </button>
    </section>
  )
}
